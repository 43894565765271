<template>
    <!--  Content Start -->
    <div class="sabc_adioshow">
         <div class="container">
            <h1>Sport Show Schedule</h1>
            <div class="sabc_adioshow_row">
               <div class="sabc_adioshow_logobtn">
                  <img class="sabc_adioshow_logo" src="/public/images/sabc_adioshow_logo3.webp" alt="Metro FM">
                  <h2>Metro FM</h2>
                  <a href="https://omny.fm/shows/metrofmsports/playlists/podcast" target="_blank"><i><img src="/public/images/sbac_hdr_watchvideobtn.webp" alt="sbac_hdr_watchvideobtn" width="20" height="20"></i>Listen</a>
               </div>
               <div class="sabc_adioshow_list">
                  <div class="sabc_adioshow_list_row">
                     <h3>Programme</h3>
                     <h3>Broadcast Day</h3>
                     <h3>Starts</h3>
                     <h3>Ends</h3>
                  </div>
                  <div class="sabc_adioshow_list_row">
                     <span>Sport Night Amplified</span>
                     <span>Monday - Friday</span>
                     <span>18:00</span>
                     <span>19:29</span>
                  </div>
                  <a href="https://omny.fm/shows/metrofmsports/playlists/podcast" target="_blank"><i><img src="/public/images/sbac_hdr_watchvideobtn.webp" alt="sbac_hdr_watchvideobtn" width="20" height="20"></i>Listen</a>
               </div>
            </div>
            <div class="sabc_adioshow_row">
               <div class="sabc_adioshow_logobtn">
                  <img class="sabc_adioshow_logo" src="/public/images/sabc_adioshow_logo7.webp" alt="Radio 2000">
                  <h2>Radio 2000</h2>
                  <a href="https://omny.fm/shows/gametime/playlists/podcast" target="_blank"><i><img src="/public/images/sbac_hdr_watchvideobtn.webp" alt="sbac_hdr_watchvideobtn" width="20" height="20"></i>Listen</a>
               </div>
               <div class="sabc_adioshow_list">
                  <div class="sabc_adioshow_list_row">
                     <h3>Programme</h3>
                     <h3>Broadcast Day</h3>
                     <h3>Starts</h3>
                     <h3>Ends</h3>
                  </div>
                  <div class="sabc_adioshow_list_row">
                     <span>Game Time</span>
                     <span>Monday - Friday</span>
                     <span>18:05</span>
                     <span>19:29</span>
                  </div>
                  <div class="sabc_adioshow_list_row">
                     <span>Radio 2000 Saturday Sport</span>
                     <span>Saturday</span>
                     <span>15:00</span>
                     <span>17:59</span>
                  </div>
                  <div class="sabc_adioshow_list_row">
                     <span>Radio 2000 Sunday Sport Wrap</span>
                     <span>Sunday </span>
                     <span>18:00</span>
                     <span>18:59</span>
                  </div>
                  <a href="https://omny.fm/shows/gametime/playlists/podcast" target="_blank"><i><img src="/public/images/sbac_hdr_watchvideobtn.webp" alt="sbac_hdr_watchvideobtn" width="20" height="20"></i>Listen</a>
               </div>
            </div>
            <div class="sabc_adioshow_row">
               <div class="sabc_adioshow_logobtn">
                  <img class="sabc_adioshow_logo" src="/public/images/audio_logo12.webp" alt="SAFM Sport">
                  <h2>SAFM Sport</h2>
                  <a href="https://omny.fm/shows/sport-on-1/playlists/podcast" target="_blank"><i><img src="/public/images/sbac_hdr_watchvideobtn.webp" alt="sbac_hdr_watchvideobtn" width="20" height="20"></i>Listen</a>
               </div>
               <div class="sabc_adioshow_list">
                  <div class="sabc_adioshow_list_row">
                     <h3>Programme</h3>
                     <h3>Broadcast Day</h3>
                     <h3>Starts</h3>
                     <h3>Ends</h3>
                  </div>
                  <div class="sabc_adioshow_list_row">
                     <span>Safm Weekday Programme</span>
                     <span>Monday - Thursday</span>
                     <span>19:00</span>
                     <span>19:59</span>
                  </div>
                  <div class="sabc_adioshow_list_row">
                     <span>Safm Weekday Programme</span>
                     <span>Friday</span>
                     <span>18:00</span>
                     <span>18:59</span>
                  </div>
                  <a href="https://omny.fm/shows/sport-on-1/playlists/podcast" target="_blank"><i><img src="/public/images/sbac_hdr_watchvideobtn.webp" alt="sbac_hdr_watchvideobtn" width="20" height="20"></i>Listen</a>
               </div>
            </div>
         </div>
      </div>
    <!--  Content end -->
</template>

<script>

    export default {
        name: 'podcasts',
        data() {
            return {}
        },
        components: {},
        mounted() {
        },
       watch: {
       },
        serverPrefetch() {
        },
        computed: {
        },
        metaInfo() {
           if (this.$store.state.siteSetting) {
                let ss = this.$store.state.siteSetting;

                let title = this.$getSafe(() => ss.podcasts_meta_title);
                let description = this.$getSafe(() => ss.podcasts_meta_description);
                let keywords = this.$getSafe(() => ss.podcasts_meta_keywords);

                return {
                    title: title,
                    description: description,
                    meta: this.$getMetaTags(title, description, keywords),
                    link: [
                        {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}
                    ],
                }
            }

        },

        methods: {
        }
    }

</script>
<style lang="scss">
@import '~@/assets/scss/audio.scss';
</style>
